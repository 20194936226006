<template>
  <!-- SPEAKERS SECTION -->
  <div id="speakers">
    <div class="container">
      <div class="row our-team responsive-text">
        <div class="col-md-12 contact_title">
          <h3 class="text-center">Excelam, impreuna.</h3>
        </div>
      </div>

      <div id="employeeslist" class="row">
       
        <div
          class="employee col-lg-custom col-md-4 col-sm-6 col-6"
          v-for="contact in contacts" :key="contact.id">
          <div class="member-profile">
            <div class="unhover_img">
              <img :src="contact.photo1" alt="" />
            </div>
            <div class="hover_img">
              <img :src="contact.photo2" alt=""/>
            </div>
            <span class="departament">{{ contact.departament}}</span>
            <h4 itemprop="name">{{ contact.nume }}</h4>
            <span class="contact-telephone"
              ><a :href="'tel:' + contact.telefon">{{ contact.telefon }}</a></span
            ><br />
            <span class="contact-email">{{ contact.email }}</span>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactpersoane", 
	computed: {
    contacts() {
      return this.$store.getters["contacts/contacts"];
    },
  },
  mounted() {
    this.$store.dispatch("contacts/getContacts");
  },
}

</script>

<style scoped>
@media (max-width: 576px) {
  #speakers h4 {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
