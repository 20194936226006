<template>
    <div id="mm-0 top" class="mm-page mm-slideout default-body contact">
        <div itemprop="haspart" itemscope itemtype="https://schema.org/ContactPage">
            <page-header :item="header_image" />
            <div class="main-area">
                <div class="first-section pages">
                    <section id="contact-us">
                        <div class="main-content contact-page">
                            <div class="container-fluid bg-grey pt50">
                                <div class="container">
                                    <h2 class="main-title">Contactează-ne</h2>
                                    <div v-if="HolidayImage.length" class="unhover_img">
                                        <img :src="HolidayImage" alt="" />
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-lg-7 col-md-7 col-sm-12 align-page"
                                        >
                                            <div class="contact-subtitle">Trimite un mesaj</div>
                                            <div class="contact-message">Ai întrebări sau ai nevoie de ajutor?  Scrie-ne folosind formularul de mai jos și îți vom răspunde cât mai curând posibil, <span style="font-family:Raleway Bold">în maxim 24 de ore lucrătoare.</span></div>
                                            <contact-form />
                                        </div>
                                        <div class="col-lg-5 col-md-5 col-sm-12">
                                            <call-now />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <contact-info />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <speakers />
                        </div>
                    </section>

                    <motto />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContactInfo from "@/components/contact/ContactInfo.vue";
import CallNow from "@/components/contact/CallNow.vue";
import Speakers from "@/components/contact/Speakers.vue";
import Motto from "@/components/contact/Motto.vue";
import PageHeader from "@/components/page_header/PageHeader.vue";
import { defineAsyncComponent } from "vue";

const ContactForm = defineAsyncComponent(() =>
    import("@/components/contact/ContactForm.vue")
);

export default {
    name: "Contact",
    components: {
        "contact-info": ContactInfo,
        "contact-form": ContactForm,
        "call-now": CallNow,
        Speakers,
        Motto,
        "page-header": PageHeader,
    },
    computed: {
        header_image() {
            return {
                path: this.$store.getters["seo/seo_image"],
            };
        },
        HolidayImage() {
         return this.$store.getters["contacts/HolidayImage"];
    },
    },
    mounted() {
        this.$store.dispatch("contacts/getHolidayImage");
        this.$store.commit("seo/setParams", {
            seo_title: "Date contact - Seminee moderne, rustice, sobe pe lemne",
            seo_description:
                "Ne puteti contacta pentru mai multe detalii privind produsele noastre: seminee moderne, sobe pe lemne, focare seminee, cosuri de fum si multe altele.",
            seo_keywords: "contact, pefoc, seminee moderne, sobe",
            seo_image: require("@/assets/images/bg_header_contact.webp"),
        });
    },
};
</script>

<style>
@import "../../assets/css/contact.css";

.contact {
    background-color: #f5f4f2;
}

.contact .main-area {
    padding-top: 50px;
}
</style>
