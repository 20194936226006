<template>
  <div class="row">
    <div class="col-md-12">
      
      <div class="phone">
        <div class="contact-subtitle">Telefon</div>
        <div class="contact-message">Dacă preferi să auzi o voce la capătul celuilalt fir, ne poți apela oricând în programul de lucru:  
          <span style="font-family:Raleway Bold">0377101500 </span> <br><br>
          Program de lucru: de luni până vineri între orele 09:00 - 17:00.
        </div>
        <a
          class="d-sm-none btn contact-btn-top"
          href="tel:0377101500"
          onclick="call();"
        >
          Apeleaza acum
        </a>
      </div>
      <div class="whatsapp">
        <div class="contact-subtitle">WhatsApp</div>
        <div class="contact-message">
          Dacă ești o persoană socială și ai nevoie de asistență, ne poți contacta și prin apel sau mesaj direct pe WhatsApp
        </div>
        <a
          class="btn contact-btn-top"
          href="https://wa.me/40748226454"
        >
          Începe conversația WhatsApp
        </a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "CallNow",
};
</script>
