<template>
  <section class="contact-us-footer text-center">
    <div class="container-fluid bg-black">
      <div class="container">
        <div class="offset-lg-2 col-lg-8">
          <p class="quote">
            “Pentru ca iubim ceea ce facem, munca nu este decat o bucurie.”
          </p>
          <p class="autor">Darius Taranu</p>
          <a href="/despre-noi">DESPRE NOI</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Motto',
}
</script>