<template>
    <div class="company-info">
        <div class="contact-subtitle">Informații generale</div>
        
        <div itemscope="" itemtype="https://schema.org/Organization">  
            <div class="contact-message">
                <p>Site-ul www.pefoc.ro este proprietatea SC PEFOC.RO SRL</p>
                <p>Cont IBAN RON: RO79INGB0000999905187437 - ING Bank N.V.</p>
                <p>Cont IBAN RON: RO43BTRLRONCRT0605996101 - BANCA TRANSILVANIA</p>
                <p>CUI: RO 19110365</p>
                <p>Reg.Com/J : J32/1494/2006</p>
            </div>  
        </div>
    </div>
</template>
